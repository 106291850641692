<template lang="pug">
	#timelineVertical
		.wrapper
			//- h2 Antes e Depois de 1965
			ul
				li(v-for="item in contents", @click="hasChildren(item) ? $emit('setContent', item) : false", :class="getImageSide(item)")
					div.texto
						h3(v-if="item.content.date") {{ item.content.date.pt }}
						h4(v-if="item.content.title") {{ item.content.title.pt }}
						p(v-if="item.content.description") {{ item.content.description.pt }}
						span(v-if="hasChildren(item)").mais +
					Imagem(:contents="item.image", :style="getImageColor(item)").img
</template>

<script>
import { components, props, excerptByWords } from '@/mixins/components'

export default {
  name: 'component-timeline-vertical',
  props,
  components,
  methods: {
    excerptByWords,
    getImageSide (item) {
      return item.content.imageSide || 'right'
    },
    getImageColor (item) {
      if (!item.content.color) { return {} }
      return {
        borderTopColor: `${item.content.color}`
      }
    },
    hasChildren (item) {
      return item.children.length
    }
  }
}
</script>

<style lang="stylus" scoped src="./TimelineVertical.styl"></style>
